import { Processing } from "../pages";

export const pl = {
  translation: {
    common: {
      step1: "Krok 1",
      step2: "Krok 2",
      step3: "Krok 3",
      download: "Pobierz",
      downloadAgain: "Pobierz ponownie",
      downloadFileToSign: "Pobierz plik do podpisania",
      moveOn: "Przejdź",
      goAgain: "Przejdź ponownie",
      next: "Dalej",
      back: "Wstecz",
      add: "Dodaj",
      addSignedFile: "Dodaj podpisany plik",
      confirm: "Zatwierdź",
      taxNo: "NIP: {{taxNo}}"
    },
    selectMethod: {
      title: "Połącz konto z KS<0>e</0>F",
      recommendedMethods: "Rekomendowane sposoby",
      otherMethods: "Pozostałe sposoby",
      environment: "Środowisko",
      production: "Produkcyjne",
      test: "Testowe",
      demo: "Demo"
    },
    trustedProfileMethod: {
      shortTitle: "Profil zaufany",
      title: "Połącz z KS<0>e</0>F za pomocą Profilu Zaufanego",
      downloadFileToSign: "Pobierz plik do podpisania",
      goToYourTrustedProfileAndSign: "Przejdź do profilu zaufanego i podpisz"
    },
    signatureMethod: {
      shortTitle: "Podpis kwalfikowany",
      title: "Połącz konto z KS<0>e</0>F za pomocą Podpisu kwalifikowanego",
      signFileInApp: "Podpisz pobrany plik w dedykowanej do tego aplikacji"
    },
    tokenMethod: {
      shortTitle: "Token",
      title: "Połącz konto z KS<0>e</0>F za pomocą Tokenu",
      moveToKSef: "Przejdź do KSeF i wygeneruj token",
      description: "Token to Twój unikalny numer, który wygenerujesz na stronie KSeF. Z racji, że token generujesz raz, ze względów bezpieczeństwa nie rekomendujemy podawania ani publikowania tokenu.",
      addToken: "Dodaj token",
      confirmToken: "Zatwierdź",
      tokenHint: "Token to ciąg 64 znaków alfanumerycznych",
      tokenError: "Wystąpił błąd podczas komunikacji z serwerem"
    },
    upload: {
      dropHere: "Upuść plik tutaj",
      dragFileHereOr: "Przeciągnij podpisany plik tutaj lub",
      selectFromComputer: "wybierz z dysku"
    },
    processing: {
      title: "Trwa nawiązywanie połączenia z KSeF",
      subtitle:
      "Możesz zamknąć to okno. Proces ten może\n trwać do 2 godzin i będzie kontynuowany w tle.",
      restart: "Rozpocznij od początku"
    },
    successPage: {
      title: "Konto zostało poprawnie połączone z KSeF",
      backToApp: "Wróć do aplikacji"
    },
    failPage: {
      title: "Nie udało się powiązać konta z KSeF",
      restart: "Rozpocznij od początku"
    },
    missingIntegrationId: {
      title: "Nie odnaleziono identyfikatora sesji",
      description: "Sprawdź czy w parametrach URL znajduje się identyfikator sesji i spróbuj ponownie.",
      setToken: "Jeśli znasz identyfikator sesji, możesz go wprowadzić ręcznie:",
      integrationId: "Identyfikator integracji",
      confirmId: "Wprowadź ID"
    },
    error: {
      title: "Wystąpił błąd",
      description: "Spróbuj ponownie później.",
      refresh: "Odśwież aplikację",
      details: "Szczegóły błędu"
    }
  }
};
