import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {Stack} from "@mui/material";
import React from "react";

export const FailIcon = () => <Stack
  justifyContent="center"
  alignItems="center"
  sx={{backgroundColor: "error.main", borderRadius: "1000px", height: "60px", width: "60px"}}>
  <ClearRoundedIcon fontSize="large" sx={{color: "#FFF"}}/>
</Stack>
