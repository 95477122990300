import { useCallback, useEffect, useRef } from "react";

export const useTimeout = (
  expirationTime: number | null,
  callback: () => void
) => {
  const timer = useRef<NodeJS.Timeout>();

  const clear = useCallback(() => {
    clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    if (expirationTime === null) {
      return;
    }

    const now = Date.now();
    timer.current = setTimeout(callback, expirationTime - now);

    return () => {
      clear();
    };
  }, [callback, expirationTime, clear]);

  return clear;
};
