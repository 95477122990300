import {Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {ButtonContent, Header, LogoPz, Signature, Token} from "../components";
import {IntegrationEnvironment, IntegrationState} from "../ServerEndpoints";
import {useStore} from "../store";

export const SelectMethod = () => {
  const {t} = useTranslation();
  const {integration, integrationId, setIntegrationId, setEnvironment, environment, setIntegrationMethod} = useStore();
  const integrationStatus = integration?.state;

  const onSelectTrustedProfile = () => setIntegrationMethod("TrustedProfile");
  const onSelectSignature = () => setIntegrationMethod("Signature");
  const onSelectToken = () => setIntegrationMethod("Token");

  useEffect(() => {
    if (integrationId) {
      setIntegrationId(integrationId);
    }
  }, [integrationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const SelectEnvironment = () => (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{t("selectMethod.environment")}</InputLabel>
      <Select<IntegrationEnvironment>
        color="primary"
        size="small"
        fullWidth
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={environment}
        label={t("selectMethod.environment")}
        onChange={(ev) => setEnvironment(ev.target.value as IntegrationEnvironment)}
      >
        <MenuItem value="Prod">{t("selectMethod.production")}</MenuItem>
        <MenuItem value="Demo">{t("selectMethod.demo")}</MenuItem>
        <MenuItem value="Test">{t("selectMethod.test")}</MenuItem>
      </Select>
    </FormControl>
  )

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Header titleLoc="selectMethod.title" action={<SelectEnvironment/>}/>

        <Stack spacing={0}>
          {integration?.name && (
            <Typography variant="body1" sx={{
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              overflow: "hidden"
            }}>
              {integration?.name}
            </Typography>
          )
          }
          <Typography variant="body2">
            {t("common.taxNo", {taxNo: integration?.taxNo ?? ""})}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2">{t("selectMethod.recommendedMethods")}</Typography>

          <ButtonContent
            onClick={onSelectTrustedProfile}
            title={t("trustedProfileMethod.shortTitle")}
            leftIcon={<LogoPz sx={{fontSize: 36}}/>}
          />

          <ButtonContent
            onClick={onSelectSignature}
            title={t("signatureMethod.shortTitle")}
            leftIcon={<Signature sx={{fontSize: 36}}/>}
          />
        </Stack>

        <Stack spacing="9px">
          <Typography variant="body2">{t("selectMethod.otherMethods")}</Typography>
          <Stack spacing={2}>
            <ButtonContent
              disabled={integrationStatus === IntegrationState.SessionSignedRequestGenerated}
              onClick={onSelectToken}
              title={t("tokenMethod.shortTitle")}
              leftIcon={<Token sx={{fontSize: 36}}/>}
              tooltip={t("tokenMethod.description")}
            />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
