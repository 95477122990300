import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {Box, BoxProps, Button, CircularProgress, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";

export type DropFileProps = BoxProps & {
  onFileUpload?: (files: File[]) => Promise<void>;
}

export const DropSignedFile: React.FC<DropFileProps> = (
  {
    children,
    onFileUpload = (files: File[]) => null,
    ...boxProps
  }) => {
  const {t} = useTranslation();
  const [callFn, setCallFn] = useState(false);
  const {getRootProps, isDragActive} = useDropzone({
    onDrop: async (files: File[]) => {
      setCallFn(true);
      await onFileUpload(files);
      setCallFn(false);
    },
    multiple: true,
    noClick: true
  });

  return (
    <Box
      {...boxProps}
      {...getRootProps()}
      style={{
        position: "relative",
        marginTop: "16px",
        border: isDragActive ? "1px dashed #0052a5" : "1px dashed #9EA5AB",
        background: isDragActive ? "#D8E6F3" : "transparent",
        borderRadius: "8px"
      }}
    >
      <Stack spacing={1} height="128px" width="100%" direction="row" justifyContent="center" alignItems="center">

        {callFn && (
          <Stack
            position="absolute"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            style={{
              background: "#D8E6F3",
              borderRadius: "8px",
              zIndex: 1
            }}
          >
            <CircularProgress sx={{color: "#FFF"}}/>
          </Stack>
        )}

        <FileUploadOutlinedIcon color={isDragActive ? "primary" : undefined}/>

        {!isDragActive && (
          <Typography variant="body2">
            {t("upload.dragFileHereOr")}
            <Button variant="text" component="label" color="primary" style={{marginTop: "-1px"}}>
              {t("upload.selectFromComputer")}
              <input
                type="file"
                id="file"
                style={{display: "none"}}
                onChange={(event) => onFileUpload(event.target.files as any)}/>
            </Button>
          </Typography>
        )}

        {isDragActive && (
          <Typography variant="body2" color="primary">
            {t("upload.dropHere")}
          </Typography>
        )}

      </Stack>
    </Box>
  );
};
