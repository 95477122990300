import ErrorIcon from "@mui/icons-material/Error";
import {Button, Container, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {useStore} from "../store";

export const MissingIntegrationId = () => {
  const {t} = useTranslation();
  const {setIntegrationId} = useStore();
  const [newIntegrationId, setNewIntegrationId] = useState<string>();

  const onSetIntegrationId = () => {
    /**
     * We update the url so that the user can see what was missing
     */
    const href = new URL(window.location.href);
    href.searchParams.set("integration-id", newIntegrationId || "");
    window.history.replaceState(null, "", href.toString());

    /**
     * We update the integrationId in the store
     */
    if (newIntegrationId !== undefined && newIntegrationId !== "") {
      setIntegrationId(newIntegrationId);
    }
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={11} pt="42px">
        <Stack alignItems="center" spacing={2}>
          <ErrorIcon color="error" sx={{fontSize: 60}}/>
          <Typography variant="h5" textAlign="center">
            {t("missingIntegrationId.title")}
          </Typography>
          <Typography variant="body2">
            {t("missingIntegrationId.description")}
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="body1">
            {t("missingIntegrationId.setToken")}
          </Typography>
          <TextField
            label={t("missingIntegrationId.integrationId")}
            variant="outlined"
            onChange={(e) => setNewIntegrationId(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={newIntegrationId === undefined || newIntegrationId === ""}
                    onClick={onSetIntegrationId}
                    variant="contained"
                    disableElevation
                    style={{borderRadius: 100}}
                  >{t("missingIntegrationId.confirmId")}</Button>
                </InputAdornment>
              )
            }}/>
        </Stack>
      </Stack>
    </Container>
  );
}
