import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {Container, Stack} from "@mui/material";
import React, {useState} from "react";

import {DropSignedFile, Header, Signature, Step} from "../components";
import { IntegrationState } from "../ServerEndpoints";
import {useStore} from "../store";

export const SignatureMethod = () => {
  const {integration, getIntegrationsData, createFileToSign, uploadSignedFile} = useStore();
  const [steps, setSteps] = useState<{ [key in number]: boolean }>({});
  const [preparedFileToDownload, setPreparedFileToDownload] = useState(false)
  const integrationStatus = integration?.state;

  const setStep = (step: number) => setSteps(s => ({...s, [step]: true}));

  const onDownloadFile = async () => {
    setPreparedFileToDownload(true);
    await createFileToSign();
    setStep(1);
    setPreparedFileToDownload(false);
    getIntegrationsData();
  }

  const onUploadFile = async (files: File[]) => {
    await uploadSignedFile(files[0]);
  }


  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Header titleLoc="signatureMethod.title" onBackPath="/"/>

        <Stack spacing={1}>
          <Step
            step={1}
            titleLoc="common.downloadFileToSign"
            buttonLabelLoc="common.download"
            done={integrationStatus === IntegrationState.SessionSignedRequestGenerated}
            icon={<FileDownloadOutlinedIcon color="primary" sx={{fontSize: 36}}/>}
            onClick={onDownloadFile}
            loading={preparedFileToDownload}
          />

          <Step
            step={2}
            titleLoc="signatureMethod.signFileInApp"
            icon={<Signature color="primary" sx={{fontSize: 36}}/>}
          />

          <Step
            step={3}
            titleLoc="common.addSignedFile"
            buttonLabelLoc="Dodaj"
            done={steps[3]}
            showDone={false}
            icon={<FileUploadOutlinedIcon color="primary" sx={{fontSize: 36}}/>}
            onClick={() => setStep(3)}
          />

          {
            steps[3] && <DropSignedFile minHeight={8 * 16} onFileUpload={onUploadFile}/>
          }

        </Stack>

      </Stack>
    </Container>
  );
}
