import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { Store, StoreData } from "../types";

export const getIntegrationsData =
  (set: SetState<StoreData>, get: GetState<Store>) => async () => {
    const integrationId = get().integrationId!;
    try {
      const response = await apiClient.integrations.getIntegrationsId(
        integrationId
      );
      const integration = response.data;
      set(() => ({
        integration
      }));
    } catch (error) {
      enqueueSnackbar(t("error.title"));
    }
  };
