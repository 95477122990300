import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { Store, StoreData } from "../types";

export const createFileToSign =
  (set: SetState<StoreData>, get: GetState<Store>) => async () => {
    const integrationId = get().integrationId!;
    const environment = get().environment!;
    const integration = get().integration!;

    if (integrationId === undefined) {
      console.warn("Integration ID is undefined");
      return;
    }

    if (environment === undefined) {
      console.warn("Environment is undefined");
      return;
    }

    try {
      const response =
        await apiClient.integrations.postIntegrationsIdAuthSignatureCreateFileToSign(
          integrationId,
          { environment },
          { format: "blob" }
        );

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `integracja-ksef-dev-${integration.taxNo}.xml`;
      link.click();
    } catch (error) {
      enqueueSnackbar(t("error.title"));
    }
  };
