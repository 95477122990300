import { Box, ThemeProvider, styled } from "@mui/material";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import React, { useEffect } from "react";

import { ErrorBoundary } from "./components";
import {
  FailPage,
  Loading,
  MissingIntegrationId,
  Processing,
  SelectMethod,
  SignatureMethod,
  SuccessPage,
  TokenMethod,
  TrustedProfileMethod
} from "./pages";
import { useStore } from "./store";
import { theme } from "./theme";

export const App: React.FC = () => {
  const {
    setIntegrationId,
    isInitialized,
    integrationMethod,
    integration,
    initialize
  } = useStore();
  let integrationId = useStore((s) => s.integrationId);
  const urlParams = new URLSearchParams(window.location.search);
  const integrationIdInParams = urlParams.get("integration-id") as string;

  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    !integrationId ||
    (integrationId !== integrationIdInParams && !!integrationIdInParams)
  ) {
    integrationId = integrationIdInParams;

    if (integrationId) {
      setIntegrationId(integrationId);
      initialize();
    }
  }

  const renderState = () => {
    if (!integrationId || integrationId === "") {
      return <MissingIntegrationId />;
    }

    if (!isInitialized) {
      return <Loading />;
    }

    /**
     *  Possible states of integration
     *   Active = "Active",
     *   Created = "Created",
     *   Invalid = "Invalid",
     *   SessionSignedRequestGenerated = "SessionSignedRequestGenerated",
     *   SessionSignedRequestProvided = "SessionSignedRequestProvided",
     *   TokenProvided = "TokenProvided",
     */
    if (integration?.state === "Invalid") {
      return <FailPage />;
    }

    if (integration?.state === "Active") {
      return <SuccessPage />;
    }

    if (
      integration?.state === "SessionSignedRequestProvided" ||
      integration?.state === "TokenProvided"
    ) {
      return <Processing />;
    }

    if (
      (integration?.state === "Created" ||
        integration?.state === "SessionSignedRequestGenerated") &&
      !integrationMethod
    ) {
      return <SelectMethod />;
    }

    if (
      (integration?.state === "Created" ||
        integration?.state === "SessionSignedRequestGenerated") &&
      integrationMethod === "TrustedProfile"
    ) {
      return <TrustedProfileMethod />;
    }

    if (
      (integration?.state === "Created" ||
        integration?.state === "SessionSignedRequestGenerated") &&
      integrationMethod === "Token"
    ) {
      return <TokenMethod />;
    }

    if (
      (integration?.state === "Created" ||
        integration?.state === "SessionSignedRequestGenerated") &&
      integrationMethod === "Signature"
    ) {
      return <SignatureMethod />;
    }

    return <FailPage />;
  };

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-error": {
      fontFamily: "Helvetica"
    }
  }));

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <SnackbarProvider
          Components={{ error: StyledMaterialDesignContent }}
          maxSnack={3}
          autoHideDuration={3000}
          variant="error"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Box mt="42px">{renderState()}</Box>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
