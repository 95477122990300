import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import { Container, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Header, Signature, Step, Token } from "../components";
import { ksefWebUrls } from "../consts";
import { useStore } from "../store";

export const TokenMethod = () => {
  const { t } = useTranslation();
  const { environment, applyToken } = useStore();
  const [token, setToken] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(false);
  const [steps, setSteps] = useState<{ [key in number]: boolean }>({});
  const setStep = (step: number) => setSteps((s) => ({ ...s, [step]: true }));

  const onMoveToKSef = () => {
    window.open(ksefWebUrls[environment], "_blank");
  };

  const onConfirmToken = async () => {
    setProcessing(true);
    await applyToken(token || "");
    setProcessing(false);
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Header titleLoc="tokenMethod.title" onBackPath="/" />

        <Stack spacing={1}>
          <Step
            color="error"
            buttonColor="error"
            buttonVariant="invert"
            textColor="white"
            step={1}
            titleLoc="tokenMethod.moveToKSef"
            description="tokenMethod.description"
            buttonLabelLoc="common.moveOn"
            showDone={false}
            infoIcon
            done={steps[1]}
            icon={<InfoIcon sx={{ fontSize: 36, color: "white" }} />}
            onClick={onMoveToKSef}
          />

          <Step
            step={2}
            titleLoc="tokenMethod.addToken"
            icon={<Token sx={{ fontSize: 36 }} />}
            buttonLabelLoc="common.add"
            showDone={false}
            done={steps[2]}
            onClick={() => setStep(2)}
          />

          {steps[2] && (
            <TextField
              disabled={processing}
              label="Token"
              variant="outlined"
              helperText={t("tokenMethod.tokenHint")}
              onChange={(e) => setToken(e.target.value)}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      loading={processing}
                      disabled={
                        token === undefined ||
                        token === "" ||
                        token?.length !== 64
                      }
                      onClick={onConfirmToken}
                      variant="contained"
                      style={{ borderRadius: 100 }}
                      disableElevation
                    >
                      {t("tokenMethod.confirmToken")}
                    </LoadingButton>
                  </InputAdornment>
                )
              }}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};
