import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Container, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DropSignedFile, Header, Step } from "../components";
import { IntegrationState } from "../ServerEndpoints";
import { useStore } from "../store";

export const TrustedProfileMethod = () => {
  const { t } = useTranslation();
  const {
    integration,
    getIntegrationsData,
    uploadSignedFile,
    createFileToSign
  } = useStore();
  const integrationStatus = integration?.state;
  const [steps, setSteps] = useState<{ [key in number]: boolean }>({});
  const setStep = (step: number) => setSteps((s) => ({ ...s, [step]: true }));
  const [preparedFileToDownload, setPreparedFileToDownload] = useState(false);
  const onDownloadFile = async () => {
    setPreparedFileToDownload(true);
    await createFileToSign();
    setStep(1);
    setPreparedFileToDownload(false);
    getIntegrationsData();
  };

  const onClickPZ = () => {
    window.open("https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER&xadesPdf=true", "_blank");
    setStep(2);
  };

  const onUploadFile = async (files: File[]) => {
    await uploadSignedFile(files[0]);
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Header titleLoc="trustedProfileMethod.title" onBackPath="/" />

        <Stack spacing={1}>
          <Step
            step={1}
            titleLoc={t("common.downloadFileToSign")}
            buttonLabelLoc="common.download"
            done={integrationStatus === IntegrationState.SessionSignedRequestGenerated}
            icon={
              <FileDownloadOutlinedIcon color="primary" sx={{ fontSize: 36 }} />
            }
            onClick={onDownloadFile}
            loading={preparedFileToDownload}
          />

          <Step
            step={2}
            titleLoc="trustedProfileMethod.goToYourTrustedProfileAndSign"
            replayLabelLoc="common.goAgain"
            buttonLabelLoc="common.moveOn"
            done={steps[2]}
            icon={<ExitToAppOutlinedIcon color="primary" sx={{ fontSize: 36 }} />}
            onClick={onClickPZ}
          />

          <Step
            step={3}
            titleLoc="common.addSignedFile"
            buttonLabelLoc="common.add"
            done={steps[3]}
            showDone={false}
            icon={<FileUploadOutlinedIcon color="primary" sx={{ fontSize: 36 }} />}
            onClick={() => setStep(3)}
          />

          {steps[3] && (<DropSignedFile minHeight={8 * 16} onFileUpload={onUploadFile} />)}
        </Stack>
      </Stack>
    </Container>
  );
};
