import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { ProvideTokenRequest } from "../../ServerEndpoints";
import { Store, StoreData } from "../types";

export const applyToken = (set: SetState<StoreData>, get: GetState<Store>) =>  async (token: string): Promise<boolean> => {
  const integrationId = get().integrationId!;
  const environment = get().environment!;

  if (integrationId === undefined) {
    console.warn("Integration ID is undefined");
    return false;
  }

  if (environment === undefined) {
    console.warn("Environment is undefined");
    return false;
  }

  const requestData: ProvideTokenRequest = {
    token: token,
    environment: environment
  };

  try{
    await apiClient.integrations.postIntegrationsIdAuthToken(
      integrationId,
      requestData,
      { 
        validateStatus: () => true 
      }
    );

    await get().initialize();
    return true

  } catch (error) {
    enqueueSnackbar(t("error.title"));
    return false;
  }
};

