import { Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProcessingIcon } from "../components";
import { PROCESSING_RENDER_TIME } from "../consts";
import { useTimeout } from "../hooks/useTimeout";
import { useStore } from "../store";

export const Processing = () => {
  const { startPolling, stopPolling, integration, resetIntegrations } =
    useStore();
  const onBackPath = "/";
  const [expirationTime, setExpirationTime] = useState<number | null>(null);
  const [isDisplayed, setIsDisplayed] = useState(false);

  const { t } = useTranslation();

  const clearTimeout = useTimeout(expirationTime, () => setIsDisplayed(true));

  useEffect(() => {
    let renderTime = Number(localStorage.getItem(PROCESSING_RENDER_TIME));

    if (!renderTime) {
      renderTime = Date.now();
      localStorage.setItem(PROCESSING_RENDER_TIME, JSON.stringify(renderTime));
    }
    setExpirationTime(renderTime + 5 * 60000);

    if (integration?.state === "Active" || integration?.state === "Invalid") {
      localStorage.removeItem(PROCESSING_RENDER_TIME);
      clearTimeout();
    }

    return () => {
      localStorage.removeItem(PROCESSING_RENDER_TIME);
    };
  }, [integration?.state, clearTimeout]);

  useEffect(() => {
    startPolling();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (integration?.state === "Invalid") {
      stopPolling();
    }

    if (integration?.state === "Active") {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [integration]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="sm">
      <Stack
        minHeight="330px"
        minWidth="600px"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Stack justifyContent="center" alignItems="center" spacing={2} mb="xxl">
          <ProcessingIcon />

          <Typography mb="lg" variant="h5" textAlign="center" maxWidth="330px">
            {t("processing.title")}
          </Typography>
          <Typography variant="body2" textAlign="center" maxWidth="330px">
            {t("processing.subtitle")}
          </Typography>
        </Stack>
        {isDisplayed && onBackPath && (
          <Button
            onClick={() => {
              resetIntegrations();
            }}
            color="primary"
            variant="contained"
            style={{ borderRadius: 100, padding: "8px 16px" }}
            disableElevation
          >
            {t("processing.restart")}
          </Button>
        )}
      </Stack>
    </Container>
  );
};
