import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { config } from "../../config";
import { Store, StoreData } from "../types";

let pollingInterval: number | undefined = undefined;

export const startPolling =
  (set: SetState<StoreData>, get: GetState<Store>) => () => {
    if (pollingInterval !== undefined) {
      console.warn(
        `Polling already working (ID: ${pollingInterval}), skipping`
      );
      return;
    }
    const pollingFn = async (step = 0) => {
      const integrationId = get().integrationId!;

      if (integrationId === undefined) {
        console.warn("Integration ID is undefined");
        return;
      }

      try {
        const response = await apiClient.integrations.getIntegrationsId(
          integrationId
        );

        const integration = response.data;

        if (
          integration.state !== "TokenProvided" &&
          integration.state !== "SessionSignedRequestProvided"
        ) {
          set((s) => ({
            ...s,
            integration
          }));
        } else {
          const delay =
            config.statusPollingInterval[step] ??
            config.statusPollingInterval[
              config.statusPollingInterval.length - 1
            ] ??
            5;
          console.debug(`Next polling call in ${delay} seconds`);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          pollingInterval = setTimeout(() => pollingFn(step + 1), delay * 1000);
        }
      } catch (error) {
        enqueueSnackbar(t("error.title"));
      }
    };

    console.debug("Start polling");
    pollingFn();
  };

export const stopPolling = () => () => {
  if (pollingInterval !== undefined) {
    console.debug("Stop polling");
    clearTimeout(pollingInterval);
  }
};
