import {SvgIcon, SvgIconProps} from "@mui/material";

export const Token: React.FC<SvgIconProps> = (props) => <SvgIcon {...props}>
  <svg width="35" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 8.375C3.00781 8.375 2.625 8.8125 2.625 9.25V19.75C2.625 20.2422 3.00781 20.625 3.5 20.625H21.875V8.375H3.5ZM0 9.25C0 7.33594 1.53125 5.75 3.5 5.75H22.75C23.6797 5.75 24.5 6.57031 24.5 7.5V21.5C24.5 22.4844 23.6797 23.25 22.75 23.25H3.5C1.53125 23.25 0 21.7188 0 19.75V9.25ZM33.25 7.5C34.1797 7.5 35 8.32031 35 9.25V19.75C35 20.7344 34.1797 21.5 33.25 21.5H26.25V7.5H33.25ZM30.625 10.5625C29.8594 10.5625 29.3125 11.1641 29.3125 11.875C29.3125 12.6406 29.8594 13.1875 30.625 13.1875C31.3359 13.1875 31.9375 12.6406 31.9375 11.875C31.9375 11.1641 31.3359 10.5625 30.625 10.5625ZM29.3125 17.125C29.3125 17.8906 29.8594 18.4375 30.625 18.4375C31.3359 18.4375 31.9375 17.8906 31.9375 17.125C31.9375 16.4141 31.3359 15.8125 30.625 15.8125C29.8594 15.8125 29.3125 16.4141 29.3125 17.125Z"
      fill="#0052A5"/>
    <path
      d="M8.14844 11.9219L7.98828 13.3594L9.44531 12.9531L9.57422 13.9375L8.24609 14.0312L9.11719 15.1914L8.23047 15.6641L7.62109 14.4414L7.08594 15.6562L6.16406 15.1914L7.02734 14.0312L5.70703 13.9297L5.85938 12.9531L7.28516 13.3594L7.125 11.9219H8.14844ZM12.5078 11.9219L12.3477 13.3594L13.8047 12.9531L13.9336 13.9375L12.6055 14.0312L13.4766 15.1914L12.5898 15.6641L11.9805 14.4414L11.4453 15.6562L10.5234 15.1914L11.3867 14.0312L10.0664 13.9297L10.2188 12.9531L11.6445 13.3594L11.4844 11.9219H12.5078ZM16.8672 11.9219L16.707 13.3594L18.1641 12.9531L18.293 13.9375L16.9648 14.0312L17.8359 15.1914L16.9492 15.6641L16.3398 14.4414L15.8047 15.6562L14.8828 15.1914L15.7461 14.0312L14.4258 13.9297L14.5781 12.9531L16.0039 13.3594L15.8438 11.9219H16.8672Z"
      fill="#0052A5"/>
  </svg>
</SvgIcon>
