import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { Store, StoreData } from "../types";

import { setIntegrationId } from "./setIntegrationId";
import { setIntegrationMethod } from "./setIntegrationMethod";

export const resetIntegrations =
  (set: SetState<StoreData>, get: GetState<Store>) => async () => {
    const integrationId = get().integrationId!;
    try {
      await apiClient.integrations.postIntegrationsIdReset(integrationId);

      setIntegrationMethod(set)(undefined);
      setIntegrationId(set)(undefined);
    } catch (error) {
      enqueueSnackbar(t("error.title"));
    }
  };
