export const envHelper = {
  toNumber: Number,
  toBoolean: (value: string | undefined): boolean =>
    value
      ? ["true", "1"].includes(value.toString().toLocaleLowerCase())
      : false,
  toArray: (separator: string) => (subject: string): string[] =>
    subject.split(separator),
  toArrayString: (subject: string): string[] =>
    envHelper
      .toArray(",")(subject)
      .map((item) => item.trim())
};


export type ConfigType = {
  apiUrl: string;
  statusPollingInterval: number[];
};

export const config: ConfigType = {
  apiUrl: process.env.REACT_APP_API_URL || "/api",
  statusPollingInterval: envHelper.toArrayString(process.env.REACT_APP_STATUS_POLLING_INTERVAL || "5").map(Number)
};
