import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware"

import {actions} from "./actions";
import {Store} from "./types";

export const store = create<Store>(
  persist(
    (set, get) => ({
      isInitialized: false,
      integrationId: undefined,
      environment: "Prod",
      ...actions(set, get as any)
    }),
    {
      name: "ksef.dev-widget-storage",
      storage: createJSONStorage(() => sessionStorage)
    }
  ) as any);
export const useStore = store;
