import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {Stack} from "@mui/material";
import React from "react";

export const DoneSmallIcon = () => <Stack
  justifyContent="center"
  alignItems="center"
  sx={{backgroundColor: "#00A507", borderRadius: "1000px", height: "32px", width: "32px"}}>
  <DoneRoundedIcon sx={{color: "#FFF"}}/>
</Stack>
