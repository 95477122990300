import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { Store, StoreData } from "../types";

export const uploadSignedFile =
  (set: SetState<StoreData>, get: GetState<Store>) =>
    async (file: File): Promise<boolean> => {
      const integrationId = get().integrationId!;
      const environment = get().environment!;

      if (integrationId === undefined) {
        console.warn("Integration ID is undefined");
        return false;
      }

      if (environment === undefined) {
        console.warn("Environment is undefined");
        return false;
      }

      try {
        await apiClient.integrations.postIntegrationsIdAuthSignatureUploadSignedFile(
          integrationId,
          file,
          {
            headers: {
              "Content-Type": "application/octet-stream"
            }
          }
        );

        await get().initialize();
        return true;
      } catch (error) {
        enqueueSnackbar(t("error.title"));
        return false;
      }
    };
