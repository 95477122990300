import {Button, Container, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {FailIcon} from "../components";
import {useStore} from "../store";

export const FailPage = () => {
  const {t} = useTranslation();
  const {resetIntegrations} = useStore();

  useEffect(() => {
    window.opener?.postMessage({type: "fail"}, "*");
  }, []);

  const onBackToApp = () => {
    resetIntegrations();
  }

  return (
    <Container maxWidth="sm">
      <Stack minHeight="330px" minWidth="600px" justifyContent="center" alignItems="center" spacing={4}>

        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <FailIcon/>

          <Typography maxWidth="330px" textAlign="center" variant="h5" color="error">
            {t("failPage.title")}
          </Typography>
        </Stack>

        <Button
          onClick={onBackToApp}
          color="primary"
          variant="contained"
          style={{borderRadius: 100, padding: "8px 16px"}}
          disableElevation
        >{t("failPage.restart")}</Button>
      </Stack>
    </Container>
  );
}
