import {IntegrationEnvironment} from "./ServerEndpoints";

export type KsefWebUrls = {
  [key in IntegrationEnvironment]: string;
}

export const PROCESSING_RENDER_TIME = "renderTime";

export const ksefWebUrls: KsefWebUrls = {
  "Prod": "https://ksef.mf.gov.pl/web/",
  "Demo": "https://ksef-demo.mf.gov.pl/web/",
  "Test": "https://ksef-test.mf.gov.pl/web/"
}
