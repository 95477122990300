import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { GetState, SetState } from "zustand";

import { apiClient } from "../../ApiClient";
import { Store, StoreData } from "../types";

export const initialize =
  (set: SetState<StoreData>, get: GetState<Store>) => async () => {
    const integrationId = get().integrationId!;

    if (integrationId === undefined) {
      console.warn("Integration ID is undefined");
      return;
    }

    try {
      const response = await apiClient.integrations.getIntegrationsId(
        integrationId
      );

      const integration = response.data;
      set((s) => ({
        isInitialized: true,
        integration,
        environment: integration.environment ?? s.environment
      }));
    } catch (error) {
      enqueueSnackbar(t("error.title"));
    }
  };
