import ErrorIcon from "@mui/icons-material/Error";
import {Button, Container, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export type ErrorBoundaryProps = {
  error: Error;
}

export const DisplayError: React.FC<ErrorBoundaryProps> = ({error}) => {
  const {t} = useTranslation();

  return (
    <Container maxWidth="sm">
      <Stack spacing={11} pt="42px">
        <Stack alignItems="center" spacing={2}>
          <ErrorIcon color="error" sx={{fontSize: 60}}/>
          <Typography variant="h5" textAlign="center">
            {t("error.title")}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {t("error.description")}
          </Typography>
        </Stack>

        <Button
          onClick={() => window.location.reload()}
          variant="contained"
          disableElevation>
          {t("error.refresh")}
        </Button>

        <Stack spacing={1}>
          <Typography variant="body2">{t("error.details")}</Typography>
          <Typography variant="body2">{error.name}: {error.message}</Typography>
        </Stack>

      </Stack>
    </Container>
  );
}
