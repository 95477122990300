import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {Stack} from "@mui/material";
import React from "react";

export const DoneIcon = () => <Stack
  justifyContent="center"
  alignItems="center"
  sx={{backgroundColor: "#00A507", borderRadius: "1000px", height: "60px", width: "60px"}}>
  <DoneRoundedIcon fontSize="large" sx={{color: "#FFF"}}/>
</Stack>
