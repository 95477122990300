import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import React from "react";
import {Trans} from "react-i18next";

import {useStore} from "../store";

export type HeaderProps = {
  titleLoc?: string;
  onBackPath?: string;
  action?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({titleLoc, onBackPath, action}) => {
  const { resetIntegrations } = useStore();

  return (
    <Stack minHeight={8 * 5} spacing={2} direction="row" alignItems="center">
      {onBackPath &&
        <IconButton
          onClick={
            () => {
              resetIntegrations()
            }
          }
          sx={{border: "1px solid #ccc"}}>
          <NavigateBeforeIcon/>
        </IconButton>}
      <Box width="100%">
        <Grid width="100%" container>
          <Grid item xs={action ? 9 : 12} justifyContent="center">
            <Stack width="100%" minHeight="100%" justifyContent="center">
              <Typography variant="h5">
                <Trans i18nKey={titleLoc}>
                  <Typography key="0" variant="h5" color="error" component="span"/>
                </Trans>
              </Typography>
            </Stack>
          </Grid>
          {
            action && (
              <Grid item xs={3}>
                {action}
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Stack>
  );
}
