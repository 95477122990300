import { LoadingButton } from "@mui/lab";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

import {DoneSmallIcon} from "./DoneSmallIcon";

export type  StepProps = {
  color?: string;
  buttonColor?: string;
  buttonVariant?: string;
  textColor?: string;
  step?: number;
  titleLoc?: string;
  description?: string;
  icon?: React.ReactNode;
  buttonLabelLoc?: string;
  replayLabelLoc?: string;
  onClick?: () => void;
  done?: boolean;
  showDone?: boolean;
  loading?: boolean;
  infoIcon?: boolean;
}

export const Step: React.FC<StepProps> = (
  {
    color = "secondary",
    buttonColor = "primary",
    buttonVariant = "contained",
    textColor,
    step,
    titleLoc,
    description,
    icon,
    buttonLabelLoc,
    replayLabelLoc,
    done = false,
    onClick = () => null,
    showDone = true,
    loading = false,
    infoIcon
  }) => {
  const {t} = useTranslation();

  return <Box bgcolor={`${color}.main`} px={3} py={2} borderRadius={1}>
    <Stack direction="row" alignItems={!infoIcon ? "center" : "stretch"} spacing={2}>
      <Box pt="4px">
        <Stack justifyContent="top" alignItems="center" minHeight="36px" minWidth="36px">
          {icon}
        </Stack>
      </Box>

      <Box flexGrow={1}>
        <Typography variant="caption" sx={{color: color === "secondary" ? "#777373" : "#FFF"}}
          color={textColor}>{t(`common.step${step}`)}</Typography>

        <Typography variant="body1" color={textColor}>{t(titleLoc)}</Typography>
        {description && <Typography mt={1} variant="body2" color={textColor}>{t(description)}</Typography>}
      </Box>
      {done && replayLabelLoc && <LoadingButton size="small" loading={loading} onClick={onClick}>{t(replayLabelLoc)}</LoadingButton>}
      {done && showDone && <DoneSmallIcon />}
      {!done && buttonLabelLoc && <Stack justifyContent="center" alignItems="center">
        <LoadingButton
          loading={loading}
          color={buttonColor as any}
          variant={buttonVariant as any}
          onClick={onClick} disableElevation
          size="small"
          style={{borderRadius: 100, padding: "8px 16px"}}
        >
          {t(buttonLabelLoc)}
        </LoadingButton>
      </Stack>}
    </Stack>
  </Box>
}
