import { GetState, SetState } from "zustand";

import { Store, StoreActions, StoreData } from "../types";

import { applyToken } from "./applyToken";
import { createFileToSign } from "./createFileToSign";
import { getIntegrationsData } from "./getIntegrationsData";
import { initialize } from "./initialize";
import { resetIntegrations } from "./resetIntegration";
import { setEnvironment } from "./setEnvironment";
import { setIntegrationId } from "./setIntegrationId";
import { setIntegrationMethod } from "./setIntegrationMethod";
import { startPolling, stopPolling } from "./startAndStopPolling";
import { uploadSignedFile } from "./uploadSignedFile";

export const actions = (
  set: SetState<StoreData>,
  get: GetState<Store>
): StoreActions => ({
  setIntegrationId: setIntegrationId(set),
  setIntegrationMethod: setIntegrationMethod(set),
  initialize: initialize(set, get),
  setEnvironment: setEnvironment(set),
  applyToken: applyToken(set, get),
  createFileToSign: createFileToSign(set, get),
  uploadSignedFile: uploadSignedFile(set, get),
  startPolling: startPolling(set, get),
  stopPolling: stopPolling(),
  resetIntegrations: resetIntegrations(set, get),
  getIntegrationsData: getIntegrationsData(set, get)
});
