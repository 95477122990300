import {ChevronRight} from "@mui/icons-material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {Button, Stack, Tooltip, Typography} from "@mui/material";
import React from "react";

export type ContentProps = {
  step?: string;
  title?: string;
  tooltip?: string;
  description?: string;
  leftIcon?: React.ReactNode;
  rightComponents?: React.ReactNode[];
}

const Content: React.FC<ContentProps> = ({tooltip, leftIcon, title, rightComponents}) => (
  <Stack
    minHeight={74}
    px="5px"
    width="100%"
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
    spacing="20px"
  >
    {leftIcon && (
      <Stack justifyContent="center" alignItems="center" minHeight="36px" minWidth="36px">
        {leftIcon}
      </Stack>
    )}
    <Stack direction="row" alignItems="center" flexGrow={1}>
      <Typography variant="body1" color="black">{title}</Typography>

      {tooltip && (
        <Tooltip title={tooltip} placement="right" arrow>
          <InfoRoundedIcon color="primary" sx={{ml: 1}}/>
        </Tooltip>
      )}

    </Stack>
    {
      rightComponents && rightComponents.map((component, index) => (
        <Stack key={index} justifyContent="center">
          {component}
        </Stack>
      ))
    }
  </Stack>
)

export type ButtonContentProps = ContentProps & {
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonContent: React.FC<ButtonContentProps> = ({onClick, disabled, ...rest}) => (
  <Button
    onClick={onClick}
    color="secondary"
    variant="contained"
    disableElevation
    disabled={disabled}
  >
    <Content {...rest} rightComponents={[<ChevronRight key={1} sx={{fontSize: 24}}/>]}/>
  </Button>
)
