import {CircularProgress, Container, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";

import {useThrowAsyncError} from "../hooks";
import {useStore} from "../store";

export const Loading = () => {
  const errorCatch = useThrowAsyncError();
  const {initialize} = useStore();

  useEffect(() => {
    initialize().catch(errorCatch);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="sm">
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress/>
      </Stack>
    </Container>
  );
}
