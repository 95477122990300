// plural keys companion - https://jsfiddle.net/sm9wgLze
import i18next, {LanguageDetectorAsyncModule, TFunction} from "i18next";
import {initReactI18next} from "react-i18next";

import {localization} from "./localization";

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: (cb) => cb("pl"),
  init: () => null
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    resources: localization,
    interpolation: {
      escapeValue: false
    }
  });


export const translate = i18next.t.bind(i18next) as TFunction;
