import {Button, Container, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {DoneIcon} from "../components";

export const SuccessPage = () => {
  const {t} = useTranslation();

  useEffect(() => {
    window.opener?.postMessage({type: "success"}, "*");
  }, []);

  const onBackToApp = () => {
    window.close();
  }

  return (
    <Container maxWidth="sm">
      <Stack minHeight="330px" minWidth="600px" justifyContent="center" alignItems="center" spacing={4}>

        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <DoneIcon/>

          <Typography maxWidth="330px" textAlign="center" variant="h5" color="success.main">
            {t("successPage.title")}
          </Typography>
        </Stack>

        <Button
          onClick={onBackToApp}
          color="primary"
          variant="contained"
          style={{borderRadius: 100, padding: "8px 16px"}}
          disableElevation
        >{t("successPage.backToApp")}</Button>
      </Stack>
    </Container>
  );
}
