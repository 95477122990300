import {createTheme} from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    invert: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 628,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      "Open Sans Variable",
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\""
    ].join(","),
    h5: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: 1.4
    },
    body1: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "none"
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    }
  },
  palette: {
    error: {
      main: "#DC173E"
    },
    primary: {
      main: "#0052a5"
    },
    success: {
      main: "#00A507"
    },
    secondary: {
      light: "#f5faff",
      main: "#f0f5fa",
      dark: "#D8E6F3",
      contrastText: "#0052a5"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      },
      variants: [
        {
          props: {variant: "invert", color: "error"},
          style: {
            color: "#DC173E",
            backgroundColor: "#fff",

            "&:hover": {
              backgroundColor: "#fff"
            }
          }
        }
      ]
    }
  },
  shape: {
    borderRadius: 10
  }
});

