/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BadRequest {
  error: string;
}

export interface Conflict {
  error: string;
}

export interface CreateFileToSignRequest {
  environment: IntegrationEnvironment;
}

export interface CreateIntegrationRequest {
  taxNo: string;
  name?: string;
  /** @format date */
  startingDate?: Date;
  webhook?: WebhookRequest;
}

export interface Forbidden {
  error: string;
}

export enum IntegrationEnvironment {
  Demo = "Demo",
  Prod = "Prod",
  Test = "Test",
}

export interface IntegrationResponse {
  /** @format uuid */
  id: string;
  environment?: IntegrationEnvironment;
  taxNo: string;
  state: IntegrationState;
  /** @format date */
  startingDate: Date;
  name?: string;
  errorMessage?: string;
}

export enum IntegrationState {
  Active = "Active",
  Created = "Created",
  Invalid = "Invalid",
  SessionSignedRequestGenerated = "SessionSignedRequestGenerated",
  SessionSignedRequestProvided = "SessionSignedRequestProvided",
  TokenProvided = "TokenProvided",
}

export interface InternalServerError {
  error: string;
}

export type MapListString = Record<string, string[]>;

export interface NotFound {
  error: string;
}

export interface Notification {
  /** @format uuid */
  id: string;
  /** @format uuid */
  integrationId: string;
  notificationType: NotificationType;
  dateCreated: string;
  payload: string;
}

export enum NotificationType {
  IntegrationStateChanged = "IntegrationStateChanged",
  NewInvoice = "NewInvoice",
}

export interface ProvideTokenRequest {
  token: string;
  environment: IntegrationEnvironment;
}

export interface ReceiveNotificationsRequest {
  notificationTypes?: NotificationType[];
}

export interface StatusResponse {
  status: string;
}

export interface Unauthorized {
  error: string;
}

export interface ValidationFailed {
  errors: MapListString;
}

export interface WebhookRequest {
  url: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title ksef-dev
 * @version dc9362a
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  notifications = {
    /**
     * No description
     *
     * @tags notifications
     * @name PostNotificationsReceive
     * @summary Get optionally filtered notifications and delete received from database
     * @request POST:/notifications/receive
     * @response `200` `(Notification)[]`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postNotificationsReceive: (data?: ReceiveNotificationsRequest, params: RequestParams = {}) =>
      this.http.request<
        Notification[],
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/notifications/receive`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-endpoints
     * @name PostNotificationsCreateMock
     * @summary Create random mock notification
     * @request POST:/notifications/create-mock
     * @response `201` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postNotificationsCreateMock: (params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/notifications/create-mock`,
        method: "POST",
        ...params,
      }),
  };
  integrations = {
    /**
     * No description
     *
     * @tags integrations
     * @name GetIntegrationsId
     * @summary Get integration by id
     * @request GET:/integrations/{id}
     * @response `200` `IntegrationResponse`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    getIntegrationsId: (id: string, params: RequestParams = {}) =>
      this.http.request<
        IntegrationResponse,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name DeleteIntegrationsId
     * @summary Delete integration by id
     * @request DELETE:/integrations/{id}
     * @response `204` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    deleteIntegrationsId: (id: string, params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name PostIntegrations
     * @summary Create new integration
     * @request POST:/integrations
     * @response `201` `string`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrations: (data: CreateIntegrationRequest, params: RequestParams = {}) =>
      this.http.request<
        string,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name PostIntegrationsIdAuthToken
     * @summary Provide token for integration.
     * @request POST:/integrations/{id}/auth/token
     * @response `202` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsIdAuthToken: (id: string, data: ProvideTokenRequest, params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}/auth/token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name PostIntegrationsIdAuthSignatureCreateFileToSign
     * @summary Create XML file containing challenge to sign.
     * @request POST:/integrations/{id}/auth/signature/create-file-to-sign
     * @response `200` `string`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsIdAuthSignatureCreateFileToSign: (
      id: string,
      data: CreateFileToSignRequest,
      params: RequestParams = {},
    ) =>
      this.http.request<
        string,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}/auth/signature/create-file-to-sign`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name PostIntegrationsIdAuthSignatureUploadSignedFile
     * @summary Upload signed file containing challenge.
     * @request POST:/integrations/{id}/auth/signature/upload-signed-file
     * @response `202` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsIdAuthSignatureUploadSignedFile: (id: string, data: File, params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}/auth/signature/upload-signed-file`,
        method: "POST",
        body: data,
        ...params,
      }),

    /**
     * No description
     *
     * @tags integrations
     * @name PostIntegrationsIdReset
     * @summary Reset integration to CREATED state
     * @request POST:/integrations/{id}/reset
     * @response `204` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsIdReset: (id: string, params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}/reset`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-endpoints
     * @name PostIntegrationsIdCheckUpdates
     * @summary Sends request to update integration queue
     * @request POST:/integrations/{id}/check-updates
     * @response `201` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsIdCheckUpdates: (id: string, params: RequestParams = {}) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/${id}/check-updates`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dev-endpoints
     * @name PostIntegrationsCheckQueue
     * @summary Sends multiple messages to queue
     * @request POST:/integrations/check-queue
     * @response `201` `void`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    postIntegrationsCheckQueue: (
      query: {
        /** @format int32 */
        messageCount: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        void,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/integrations/check-queue`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  status = {
    /**
     * No description
     *
     * @tags status
     * @name GetStatus
     * @summary Healthcheck: get status of the service
     * @request GET:/status
     * @response `200` `StatusResponse`
     * @response `400` `BadRequest`
     * @response `401` `Unauthorized`
     * @response `403` `Forbidden`
     * @response `404` `NotFound`
     * @response `409` `Conflict`
     * @response `422` `ValidationFailed`
     * @response `500` `InternalServerError`
     */
    getStatus: (params: RequestParams = {}) =>
      this.http.request<
        StatusResponse,
        BadRequest | Unauthorized | Forbidden | NotFound | Conflict | ValidationFailed | InternalServerError
      >({
        path: `/status`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
